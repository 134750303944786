.page-root
{
  background-color: #ffffff;
}

h1
{
  font-size: 28px;
  color: rgba(114,103,135,1);
  line-height: 1.2;
  margin-bottom: 14px;
}

h2
{
  color: black;
  margin-top: 0px;
  line-height: 0.7;
  font-size: 15px;
  font-weight: 40;
}

.subpage-title
{
  font-size: 28px !important;
  margin-bottom: 10px !important;
  margin-top: 24px !important;
}

.subpage-subtitle
{
  font-size: 16px !important;
  font-weight: 200 !important;
  line-height: 1.2;
  margin-top: 10px !important;
  margin-bottom: 24px !important;
}

.purple-text
{
  color: #44344F !important;
}

.white-text
{
  color: #ffffff !important;
}

.modebar
{
  display: none !important;
}

input[type=text], input[type=password]
{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.purple-button
{
  background-color: #7676C0 !important;
  color: #ffffff !important;
  border: 4px solid #ffffff !important;

}

.white-button
{
  background-color: #ffffff !important;
  color: #44344F !important;
  border: 4px solid #44344F !important;
}

.button-with-text
{
  font-size: 20px !important;
  line-height: 1.9 !important;
  font-style: bold;
  text-align: left;
}

button:hover
{
  opacity: 0.8;
}

button[disabled]
{
  opacity: 0.65;
}

button[disabled]:hover
{
  opacity: 0.65;
}

.ui.modal
{
  top: 35px;
  left: 50% !important;
}

.modal-logo
{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.smaller-margins
{
  margin: 10px !important;
}

span.psw {
  float: right;
  padding-top: 16px;
  margin: 5px 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.menu.transition.visible {
  top: 28px;
  left: -18px;
  border: none;
  border-radius: 0;
}

.centered-down-button
{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}

.centered-from-button
{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}

.down-arrow
{
  top: 40px;
  margin: 10px 20px;
}

.incoming-arrow
{
  top: -20px;
  margin: 50px 10px;
}

.hidden
{
  visibility: hidden;
}

.site-footer
{
  width: 80%;
  left: 10%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

#feedback-modal {
  height: 470px;
  background-color: #ffffff;
  text-align: center;
  padding: 40px;
}

#image-page {
  width: 100%;
  height: 98vh;
  min-height: 80vh;
  background-color: #ffffff;
  margin: 30px 0 0;
  padding: 50px 50px;
}

#template-page-container {
  min-height: 90vh;
  margin-bottom: 70px;
}

.feedback-modal p {
  color: black;
  line-height: 1.2em;
  font-size: 15px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.unauthorized-modal-section
{
  padding: 10px 50px;
}

.submit-feedback-button
{
  margin: 20px;
}

textarea {
  background-color: #ffffff;
  color: #7676C0;
  font-size: 12px;
  padding: 4px 4px;
  border: 1px solid #000000;
  width: 80%;
  height: 150px;
  display: inline-block;
  margin-top: 20px;
}

textarea::placeholder {
  color: #7676C0;
  font-size: 12px;
}


/* HIDE RADIO */
.hiddenradio [type=radio] {
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.hiddenradio [type=radio] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
.hiddenradio [type=radio]:checked + img {
  outline: 2px solid #000000;
}

.site-footer > .ui.segment
{
  margin: 0 !important;
}

.company-filter-form
{
  width: 100%;
  padding: 10px 20px;
}

#radio-image {
  width: 50px;
  height: 50px;
}

#radio-input {
  margin-left: 20px;
  padding-left: 0px;
  margin-right: 0px;
}

/* Change styles for span and cancel button on extra small screens */
@media (max-width: 500px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }

  .button-with-text
  {
    font-size: 14px !important;
    line-height: 1.25em !important;
    font-style: bold;
    text-align: left;
  }

  .down-arrow
  {
    top: 40px;
    margin: 10px 5px;
  }

  .centered-down-button
  {
    margin-left: 40px;
    margin-bottom: 60px;
  }

  .centered-from-button
  {
    margin-left: -20px;
    margin-bottom: 60px;
  }

  #template-page-container
  {
    margin-top: 40px;
  }
}