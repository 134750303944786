@media screen and (max-width: 1310px) {
  #unicorn-metric-block
  {
    padding-left: 2%;
    padding-right: 2%;
  }
}

#unicorn-metric-block > h3, 
#unicorn-metric-block > p
{
  color: #ffffff;
  text-align: center;
}

#unicorn-metric-block hr
{
  color: #ffffff;
  margin: 25px 10px 15px;
}

.unicorn-metric-chart-label
{
  width: 90%;
  left: 5%;
  margin: 30px auto 20px;
}

.ui.list
{
  margin-top: 0 !important;
}

#social-sharing h3
{
  font-size: 11pt;
}

#social-sharing-links
{
  width: 100%;
  text-align: center;
}

.social-link
{
  display: inline-block;
  margin: 0 5%;
}

.social-link img
{
  width: 35px;
  height: 35px;
}

.results-company-filter
{
  padding: 10px;
  margin: 0 -21px 0 -21px !important;
}

.results-company-filter > .ui.container
{
  width: 100% !important;
}

@media (max-width: 500px) {
  .hidemobile {
    display: none;
  }

  .onlymobile {
    display: inline-block;
    color: #7676C0;
    font-size: 24px;
    line-height: 1.9;
    margin: 0px;
  }

  .comparison-title-text-block {
    width: 90%;
  }

  .ui.container .results-block {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
  }

  .results-company-filter
  {
    padding: 10px;
    margin: 0 !important;
  }

  .mobile-chart-column
  {
    width: 90%;
    margin: 0 auto;
    left: 5%;
  }

}

@media (min-width: 500px) {
  .onlymobile {
    display: none;
  }

  .hidemobile {
    display: inline-block;
  }

  .ui.modal {
    left: 40%;
  }
}
