.account-menu-container
{
  margin: 40px 30px 40px 0;
}

.account-home-header
{
  margin-top: 10px !important;
}

.account-form-container
{
  width: 90%;
  left: 5%;
  padding: 20px;
  margin: 0 auto;
}

.change-password-form .ui.form .field>label {
    font-size: 15px;
    font-style: italic;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    float: left;
}

.account-form-container .ui.button
{
  margin: 20px auto;
}

@media (max-width: 500px) {
  .ui.container .user-history-block
  {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
