.ui.container {
    width: 800px;
}

.ui.segment {
    padding: 0px;
    margin: 40px 0;
}

@media only screen and (min-width: 1300px) {
    .ui.grid.container {
        width: calc(1227px + 2rem) !important;
    }
}

#filter-page {
    background-color: #7676C0;
    margin: 0 0;
    padding: 0px;
}

/* For some reason, this makes the filters line up */
.ui.selection.dropdown
{
    min-width: 100px !important;
}

#filter-form {
    width: 100%;
    min-height: 95vh;
    background-color: #7676C0;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

#filter-contents {
    top: 40%;
    left: 50%;
    margin: 0 auto;
}

#filter-subheader {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
}

#form-alert {
    color: #96020e;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
}

#form-disclaimer {
    font-size: 13px;
    font-weight: 200;
    color: #f7f7f7;
    line-height: 1.2;
    margin-top: 30px;
    text-align: center;
}

.results-button
{
    margin-bottom: 50px;
}

.email-submit-box
{
    width: 50%;
    margin: 25px auto;
    left: 25%;
}

.email-submit-box .field, .ui.input
{
    height: 50px;
}

.ui.form .field>label {
    color: #f7f7f7;
    font-size: 15px;
    font-style: italic;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    float: left;
}

input[type=number] {
    height: 50px;
    background-color: #f7f7f7;
    color: #726787;
    font-size: 14px;
    border-radius: 8px;
}

.ui.form::placeholder {
    color: #726787;
    font-size: 14px;
    text-align: left;
}

body {
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;

    color: #f7f7f7;
    font-size: 13px;
}

html {
    scroll-behavior: smooth;
}

.ui.form input, select
{
    background: #f7f7f7 !important;
}

.ui.selection.dropdown
{
    background: #f7f7f7 !important;
}

@media (max-width: 500px) {
    .hidemobile {
        display: none;
    }

    #filter-contents {
        position: relative;
        top: 0%;
        left: 0%;
        -ms-transform: translate(-0%, -0%);
        transform: translate(-0%, -0%);
    }

    #submit-button {
        width: 260px;
        font-size: 14px;
    }
}