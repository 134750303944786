.benchmarks-section-header
{
  padding: 20px;
  background-color: #7676C0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.benchmarks-section-header > .ui.header
{
  margin: 0px 10px;
}

.benchmarks-section-header > .ui.inverted.button
{
  box-shadow: none !important;
}

.benchmarks-section-content
{
  margin: 40px auto;
  width: 100% !important;
}

.benchmarks-section-text
{
  margin-top: 25px;
}