#about-title
{
    margin-top: 30px;
}

.about-grid
{
    margin: 40px 0px 70px;
}

.about-button
{
    margin-bottom: 60px;
}