.blog-chart-block
{
    margin: 60px 0 80px;
}

.blog-content-block
{
    color: #181f1C;
    font-size: 16px;
    text-align: 'left';
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    line-height: 1.3;
}

.blog-content-block h1
{
    font-size: 36px;
    font-weight: 700;
    color: #181f1C;
}

.blog-content-block h2
{
    margin: 36px 0;
    font-size: 36px;
    font-weight: 700;
    color: #181f1C;
    line-height: 1.2;
}

.blog-content-block h3
{
    font-size: 22px;
    font-weight: 700;
    font-style: italic;
    color: #181f1C;
}

.blog-content-block img
{
    align-content: center;
    font-weight: 700;
    font-style: italic;
    color: #181f1C;
}

.blog-home-button
{
    margin-top: 50px;
}

.blog-home-button
{
    margin-top: 50px;
}

.no-score-section .ui.header
{
    margin-bottom: 2px;
}

.no-score-section .centered-down-button
{
    margin: 30px 20px 20px 40px;
}

.no-score-section .down-arrow
{
    top: 20px;
    margin: 1px;
}

.no-score-section .ui.button
{
    padding: 8px 14px;
    font-size: 16px !important;
    line-height: 1.2 !important;
}