.modal-login-container > form,
.modal-login-container >label, 
.modal-login-container > input, 
.modal-login-container > p
{
  color: black !important;
}

.modals.dimmer.ui.scrolling.modal
{
  position: auto !important;
  margin: none !important;
}

.modal .ui.form .field>label {
    font-size: 15px;
    font-style: italic;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: black;
    float: left;
}

.login-header
{
  text-align: center;
  color: black;
  margin: 5px !important;
}

.partial-width
{
  width: 60%;
  left: 20%;
  margin: 0 auto;
}

.user-login-form
{
  overflow: hidden;
  margin-top: 20px;
}

.user-login-cta
{
  margin: 20px 0;
}

.login-divider
{
  width: 70%;
  margin-left: 15% !important;
}

.social-login-wrapper
{
  overflow: hidden;
  margin: 10px auto 30px;
}

.sign-up-wrapper
{
  margin-bottom: 40px;
}

.no-style-button
{
  background:none;
  border:none;
  color: black;
}

.forgot-password-container
{
  margin: 10px auto 20px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-text {
  text-align: center;
  color: black;
  margin: 20px;
}

.logout-cta {
  overflow: hidden;
  text-align: center;
  color: black;
  margin: 20px;
}

#facebook-login
{
  float: left;
}

#google-login
{
  float: right;
}

@media (max-width: 950px)
{
  #facebook-login
  {
    float: none;
    display: table;
    margin: 10px auto;
  }

  #google-login
  {
    float: none;
    display: table;
    margin: 20px auto;

  }
}