.ui.container {
    width: 800px;
}

.ui.segment {
    padding: 0px;
    margin: 40px 0;
}

@media only screen and (min-width: 1300px) {
    .ui.grid.container {
        width: calc(1227px + 2rem) !important;
    }
}

#welcome-title {
    margin-bottom: 24px;
    margin-top: 40px;
}

#perf-index {
    padding: 0px;
    margin: 0px;
    background-color: #f7f7f7;
}

#perf-bg {
    width: 100%;
    min-height: 95vh;
    background-color: #f7f7f7;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

a.anchor
{
    display: block;
    position: relative;
    top: -30px;
}

body {
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;

    color: #f7f7f7;
    font-size: 13px;
}

html {
    scroll-behavior: smooth;
}

.filter-button
{
    margin: 40px 0 80px;
}

@media (max-width: 500px) {
    .hidemobile {
        display: none;
    }

    #welcome-title {
        font-size: 30px;
    }

    #welcome-arrow {
        margin-top: 60px;
        margin-bottom: 50px;
    }

    #plots-container
    {
        margin-bottom: 20px;
    }
}